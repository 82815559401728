<template>
  <div class="container" v-if="username">
    <div class="text-h4 my-5">Do you like to continue as {{ username }}?</div>
    <v-btn @click="onSubmit" color="primary" class="mr-4">Yes</v-btn>
    <v-btn @click="handleLogOut()" outlined color="primary"
      >No, log me out</v-btn
    >
  </div>
  <div v-else class="text-h4 m-10">
    Welcome!
    <v-btn @click="handleLogOut()" outlined color="primary"
      >Go to Home Page</v-btn
    >
  </div>
</template>
<script>
import { getUsernameFromToken, logOut } from "@/auth/auth.service";

export default {
  name: "ContinueAs",
  data: () => ({
    username: getUsernameFromToken(),
  }),
  methods: {
    handleLogOut() {
      logOut();
    },
    onSubmit() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
